



































import { Component, Vue, Prop } from 'vue-property-decorator';
import Checkbox from '@/themes/v1/components/Checkbox.vue';
import Avatar from './Avatar.vue';
import { ReplyAuthorType } from '@/modules/sts/chat';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    Checkbox,
    Avatar,
  },
  directives: { TestLocator },
})
export default class TicketsItem extends Vue {
  @Prop({ type: Boolean })
  selected!: boolean;

  @Prop({ type: Boolean })
  disableSelect!: boolean;

  @Prop({ type: String })
  title!: string;

  @Prop({ type: String })
  date!: string;

  @Prop({ type: String })
  message!: string;

  @Prop({ type: Boolean })
  isNew!: boolean;

  @Prop({ type: Boolean })
  disabled!: boolean;

  @Prop({ type: Boolean })
  closed!: boolean;

  @Prop({
    type: String,
    default: ReplyAuthorType.Operator,
    validator(val: ReplyAuthorType) {
      return Object.values(ReplyAuthorType).includes(val);
    },
  })
  messageType!: ReplyAuthorType;

  changeSelect(value) {
    this.$emit('changeSelect', value);
  }
  click() {
    if (this.disabled) return;
    this.$emit('click');
  }
}
