































































































































































import { Component, Vue, Inject } from 'vue-property-decorator';
import { SsrCtx, WithAsyncData, WithMeta } from '@/core/vue.types';
import { TicketListItem } from '@/modules/sts/chat';
import Box from '../../../components/Box/Box.vue';
import BoxHeader from '../../../components/Box/BoxHeader.vue';
import BoxContent from '../../../components/Box/BoxContent.vue';
import ScrollBox from '../../../components/ScrollBox.vue';
import TicketItem from './components/TicketItem.vue';
import InlineCtrlWithLabel from '../../../components/InlineCtrlWithLabel.vue';
import Checkbox from '../../../components/Checkbox.vue';
import ButtonsGroup from '../../../components/ButtonsGroup.vue';
import { humanizingTime } from '@/core/utils';
import { toDate } from '@/core/filters';
import orderBy from 'lodash/orderBy';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import { MetaInfo } from 'vue-meta';
import { ChatService } from '@/modules/sts/chat/chat.service';
import {
  replaceWithLangAndPlatform,
  universalRedirect,
} from '@/themes/v1/fns/router';
import { AxiosError } from 'axios';
import { clearAuthCookies } from '@/themes/v1/middlewares/auth.middleware';
import DiscussionDeletePopup from '@/themes/v1/views/sts/tickets/components/DiscussionDeletePopup.vue';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import DiscussionNotificationPopup from './components/DiscussionNotificationPopup.vue';
import Spinner from '@/themes/v1/components/Spinner.vue';
import FixedPanel from '@/themes/v1/components/FixedPanel.vue';
import GrayBox from './components/GrayBox.vue';
import { emitBlockResized } from '@/themes/v1/components/FixedPanel.vue';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    DiscussionDeletePopup,
    ButtonsGroup,
    Checkbox,
    InlineCtrlWithLabel,
    TicketItem,
    ScrollBox,
    BoxContent,
    BoxHeader,
    Box,
    DiscussionNotificationPopup,
    Spinner,
    FixedPanel,
    GrayBox,
  },
  directives: { TestLocator },
})
export default class TicketsList
  extends Vue
  implements WithMeta, WithAsyncData {
  @Inject()
  chatService!: ChatService;

  loading = true;
  error = false;
  err = null;
  tickets: TicketListItem[] = [];
  selected: TicketListItem[] = [];
  deleting = false;
  showDeletePopup = false;

  playerStore = getModule(PlayerStore, this.$store);

  $refs!: {
    fixedCreatingBlock: HTMLDivElement;
  };

  get playerId() {
    return this.playerStore.playerId;
  }

  async asyncData(ctx: SsrCtx): Promise<any> {
    let tickets: TicketListItem[] = [];
    const playerStore = getModule(PlayerStore, ctx.store);
    // Do not do anything if user was not auth! AsyncData will run in parallel mode (for any route segment=component) after route resolved
    if (playerStore.isAuth) {
      const chatService = resolveFromCtx<ChatService>('chatService', ctx);
      try {
        tickets = await humanizingTime(
          chatService.getTickets(playerStore.playerId)
        );
        if (!tickets.length) {
          universalRedirect(ctx, 'problems');
        }

        return {
          tickets,
          loading: false,
        };
      } catch (e) {
        if (e.message === 'REDIRECT_ERROR') {
          throw e;
        }
        const err = e as AxiosError;
        if (err.response?.status === 401) {
          playerStore.setAuth(false);
          clearAuthCookies(ctx);
          universalRedirect(ctx, '/tickets/auth-expired');
        }
      }
    }
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.tickets.support-mail') as string,
    };
  }

  async load() {
    try {
      this.loading = true;
      this.tickets = await humanizingTime(
        this.chatService.getTickets(this.playerId)
      );
      this.error = false;
      if (!this.tickets.length) {
        this.goToCreate();
      }
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  toggleSelect(ticket: TicketListItem) {
    if (this.selected.includes(ticket)) {
      this.selected = this.selected.filter((x) => x !== ticket);
    } else {
      this.selected = [...this.selected, ticket];
    }

    if (this.$refs.fixedCreatingBlock) {
      emitBlockResized(this.$refs.fixedCreatingBlock);
    }
  }

  selectAll() {
    this.selected = [...this.tickets.filter((x) => x.isClosed)];

    if (this.$refs.fixedCreatingBlock) {
      emitBlockResized(this.$refs.fixedCreatingBlock);
    }
  }

  cancelSelected() {
    this.selected = [];
    if (this.$refs.fixedCreatingBlock) {
      emitBlockResized(this.$refs.fixedCreatingBlock);
    }
  }

  toTicket(ticket: TicketListItem) {
    this.$router.push(
      this.$routerHelper.withPlatformAndLang(
        this.$route,
        `tickets/${ticket.number}`
      )
    );
  }

  toDate(dateString: string) {
    return toDate(dateString);
  }

  goToCreate() {
    if (!process.server) {
      replaceWithLangAndPlatform(this.$router, 'problems');
    }
  }

  get sortedTickets(): TicketListItem[] {
    return orderBy(
      this.tickets,
      ['isClosed', 'isUnread', 'lastReplyTimestamp', 'number'],
      ['asc', 'desc', 'desc', 'desc']
    );
  }

  async remove() {
    if (this.deleting === true) {
      return;
    }
    try {
      this.deleting = true;
      await humanizingTime(
        this.chatService.remove(
          this.playerId,
          this.selected.map((x) => x.number)
        )
      );
      this.tickets = this.tickets.filter((x) => !this.selected.includes(x));
      this.selected = [];
      this.showDeletePopup = false;
      if (this.tickets.length === 0) {
        this.goToCreate();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.deleting = false;
    }
  }
}
