












import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { BeforeUpdate } from '@/core/vue.types';

@Component({
  model: {
    prop: 'value',
    event: 'changeValue',
  },
})
export default class Checkbox extends Vue implements BeforeUpdate {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  $refs!: {
    checkbox: HTMLInputElement;
  };

  beforeUpdate() {
    this.$refs.checkbox.checked = this.value;
  }

  @Emit('changeValue')
  changeValue() {
    const newValue = !this.value;
    this.$refs.checkbox.checked = this.value;
    return newValue;
  }

  @Emit('click')
  click() {
    return;
  }
}
