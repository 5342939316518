





























import { Component, Vue } from 'vue-property-decorator';
import Popup from '@/themes/v1/components/Popup.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxIcon from '@/themes/v1/components/Box/BoxIcon.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import ButtonsGroup from '@/themes/v1/components/ButtonsGroup.vue';
import { getModule } from 'vuex-module-decorators';
import { ChatStore } from '@/themes/v1/stores/chat.store';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClickOutside = require('vue-click-outside');

@Component({
  components: { ButtonsGroup, BoxContent, BoxIcon, Box, Popup },
  directives: { ClickOutside },
})
export default class DiscussionNotificationPopup extends Vue {
  private readonly chatStore = getModule(ChatStore, this.$store);

  get notification() {
    return this.chatStore.notification;
  }

  close() {
    this.chatStore.setNotification(null);
  }
}
